import { isNeitherNilOrEmpty } from './utils';
import { utteranceFromElementLabel } from './utteranceFromElementLabel';

/**
 * Returns string of text collected from element
 * {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby aria-labelledby} or
 * {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label aria-label}
 * attributes and or text content.
 *
 * **Example Usage**:
 * ```
 * utteranceFromElementOrText(document.getElementById('target-button'));
 * ```
 *
 *
 * **Button with text content**:
 * ```
 * <button id="target-button">I am a simple button</button>
 * ```
 * Output: ```"I am a simple button"```
 *
 *
 * **Button with nested element content**:
 * ```
 * <button id="target-button">
 *   <span>Watch</span>
 *   <img alt="Mets" src="../mets.svg" />
 *   <span>VS</span>
 *   <img alt="Yankees" src="../yankees.svg" />
 * </button>
 * ```
 * Output: ```"Watch Mets VS Yankees"```
 *
 *
 * **Button with aria-label**:
 * ```
 * <button id="target-button" aria-label="I am an aria label that overrides the button text content">
 *   This text will be ignored
 * </button>
 * ```
 * Output: ```"I am an aria label that overrides the button content"```
 *
 *
 * **Button with aria-labelledby**:
 * ```
 * <h1 id="button-title">I provide a label that overrides the button text content</h1>
 * <button id="target-button" aria-labelledby="button-title">This text will be ignored</button>
 * ```
 * Output: ```"I provide a label that overrides the button text content"```
 */

export const utteranceFromElementOrText = (elementOrText: Element | Text | null): string => {
  if (elementOrText instanceof Element) {
    const element = elementOrText;
    const labelUtterance = utteranceFromElementLabel(element);

    if (element.getAttribute('aria-hidden') === 'true') {
      return '';
    }
    if (labelUtterance) {
      return labelUtterance;
    }

    if (element instanceof HTMLImageElement && element.alt) {
      return element.alt;
    }

    if (element instanceof HTMLInputElement) {
      return element.value || element.defaultValue;
    }

    if (element.childNodes.length) {
      return Array.prototype.slice
        .call(element.childNodes)
        .map((childElement) => utteranceFromElementOrText(childElement))
        .filter(isNeitherNilOrEmpty)
        .join(' ');
    }

    return '';
  }

  if (elementOrText instanceof Text && elementOrText.textContent) {
    return elementOrText.textContent.trim();
  }

  return '';
};
