import { datadogRum } from '@datadog/browser-rum';
import { actionEventIds, AdobeTracker, ClickStatus } from 'analytics/adobe';
import { GameMenuOption } from 'components/video/GameMenu';
import { DETAILS } from 'constants/feeds';
import { ROUTES } from 'constants/screens';
import { useController } from 'hooks';
import { useAdobeGameMenuParams } from 'hooks/player';
import { usePrevious } from 'hooks/usePrevious';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { selectOpenModal } from 'store/app';
import { selectIntervals } from 'store/config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectGameMenuOption,
  selectIsBuffering,
  selectIsFfOrRw,
  selectIsPlaying,
  selectIsStarted,
  selectIsVideoContentVisible,
  selectPlayerError,
  selectPressedLinescoreInning,
  setGameMenuOption,
  setIsPlaybackMarkerFocused,
  setIsVideoContentVisible,
  setPressedLinescoreInning,
} from 'store/player';
import { selectAdobeMediaParams } from 'store/selectedVideo';
import { Buttons } from 'utils/buttons';

const { VIDEO_PLAYER_CONTROLS_OPEN, VIDEO_PLAYER_DETAILS_CLICK } = actionEventIds();
const { GAME_VIDEO } = ROUTES;

export function useVideoContentVisibility() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const fromView = pathname === GAME_VIDEO ? 'GameVideoView' : 'ForgeVideoView';

  const adobeMediaParams = useAppSelector(selectAdobeMediaParams);
  const isPlaying = useAppSelector(selectIsPlaying);
  const isVideoContentVisible = useAppSelector(selectIsVideoContentVisible);
  const playerError = useAppSelector(selectPlayerError);
  const isStarted = useAppSelector(selectIsStarted);
  const isFfOrRw = useAppSelector(selectIsFfOrRw);
  const isBuffering = useAppSelector(selectIsBuffering);
  const { VIDEO_CONTROLS_TIMEOUT_INTERVAL } = useAppSelector(selectIntervals);
  const openModal = useAppSelector(selectOpenModal);
  const gameMenuOption = useAppSelector(selectGameMenuOption);
  const pressedLinescoreInning = useAppSelector(selectPressedLinescoreInning);

  const timer = useRef<ReturnType<typeof setTimeout>>();
  const [prevOpenModal, setPrevOpenModal] = useState(!!openModal);

  const detailsParams = useAdobeGameMenuParams(DETAILS);

  const onHide = useCallback(() => {
    dispatch(setIsVideoContentVisible(false));
    dispatch(setIsPlaybackMarkerFocused(false));
    dispatch(setGameMenuOption(null));
    datadogRum.startView({ name: fromView });

    if (gameMenuOption === GameMenuOption.Details) {
      AdobeTracker.trackAction(VIDEO_PLAYER_DETAILS_CLICK(ClickStatus.Close), detailsParams);
    }
  }, [detailsParams, dispatch, fromView, gameMenuOption]);

  const hideWithTimer = useCallback(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (playerError) return;

      onHide();
    }, VIDEO_CONTROLS_TIMEOUT_INTERVAL);
  }, [VIDEO_CONTROLS_TIMEOUT_INTERVAL, onHide, playerError]);

  useEffect(() => {
    setPrevOpenModal(!openModal);
    if (!openModal) hideWithTimer();
  }, [hideWithTimer, openModal, prevOpenModal]);

  // Always start video with content visible
  const prevIsStarted = usePrevious(isStarted);
  useEffect(() => {
    if (!prevIsStarted && isStarted) {
      dispatch(setIsVideoContentVisible(true));
      hideWithTimer();
    }
  }, [dispatch, hideWithTimer, isStarted, prevIsStarted]);

  // Activates hideWithTimer when done seeking, especially when rewinding
  // to the beginning of a stream
  useEffect(() => {
    if (isPlaying) {
      hideWithTimer();
    }
  }, [hideWithTimer, isPlaying]);

  useEffect(() => {
    if (isFfOrRw) {
      clearTimeout(timer.current);
      dispatch(setIsPlaybackMarkerFocused(true));
      dispatch(setIsVideoContentVisible(true));
    }
  }, [dispatch, isFfOrRw]);

  const handleHideControls = () => {
    clearTimeout(timer.current);
    window.speechSynthesis.cancel();
    onHide();
  };

  const handleShowControls = useCallback(() => {
    clearTimeout(timer.current);
    if (pressedLinescoreInning) {
      dispatch(setPressedLinescoreInning(false));
      return;
    }

    if (!isVideoContentVisible) {
      dispatch(setIsPlaybackMarkerFocused(true));
      dispatch(setIsVideoContentVisible(true));
      AdobeTracker.trackAction(VIDEO_PLAYER_CONTROLS_OPEN, adobeMediaParams);
    }

    if (!openModal) hideWithTimer();
  }, [
    adobeMediaParams,
    dispatch,
    hideWithTimer,
    isVideoContentVisible,
    openModal,
    pressedLinescoreInning,
  ]);

  const shouldEnableController = !isFfOrRw && !isBuffering && !playerError;
  const showControlsEvent = {
    enabled: shouldEnableController,
    event: handleShowControls,
  };
  const isCircleButtonEnabled =
    !!prevOpenModal && !openModal && isVideoContentVisible && !playerError;

  useController({
    [Buttons.back]: {
      enabled: isCircleButtonEnabled,
      event: handleHideControls,
    },
    [Buttons.toggleVideoContentVisibility]: showControlsEvent,
  });

  return { isVideoContentVisible };
}
