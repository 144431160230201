import { actionEventIds, AdobeTracker } from 'analytics/adobe';
import { useScrubberBarTTS } from 'components/video/ScrubberBar/useScrubberBarTTS';
import { ROUTES } from 'constants/screens';
import { usePlayerContext } from 'context/PlayerContext';
import { useTextToSpeech } from 'hooks/tts/useTextToSpeech/useTextToSpeech';
import { useController } from 'hooks/useController/useController';
import { useDateFromURL } from 'hooks/useDateFromURL';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { selectOffsets } from 'store/config';
import { selectIsDSSExperience } from 'store/experience';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchPlayByPlayFlow } from 'store/playByPlay';
import {
  PlaybackAction,
  resetPlayer,
  seekInterrupted,
  selectCurrentTime,
  selectIsBuffering,
  selectIsFfOrRw,
  selectIsPlaybackMarkerFocused,
  selectIsVideoContentVisible,
  selectSeekOffset,
  selectSeekPointSeconds,
  selectSeekSpeedMultiplier,
  selectStartTime,
  selectTotalTime,
  setIsAtLivePoint,
  setPlaybackAction,
  setSeekOffset,
  setSeekPointSeconds,
  setSeekSpeedMultiplier,
} from 'store/player';
import { selectAdobeMediaParams, selectIsLive } from 'store/selectedVideo';
import { Buttons } from 'utils/buttons';
import { yearMonthDay } from 'utils/date';
import { msToSeconds } from 'utils/time';
import {
  calculatePlayTime,
  decrementSeekRate,
  incrementSeekRate,
  selectSeekSpeedInterval,
} from './utils';

const { FF, PAUSE_FF, PAUSE_RW, RW } = PlaybackAction;
const { GAMES } = ROUTES;
const { VIDEO_PLAYER_CONTROLS_SCRUB_JUMP, VIDEO_PLAYER_FAST_FORWARD, VIDEO_PLAYER_REWIND } =
  actionEventIds();

export function useVideoSeek() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { date } = useDateFromURL();
  const { seekTo } = usePlayerContext();
  const { speak, t } = useTextToSpeech();
  const [params] = useSearchParams();
  const gamePk = params.get('gamePk');
  const [shouldFetchPlayByPlay, setShouldFetchPlayByPlay] = useState(false);
  const adobeMediaParams = useAppSelector(selectAdobeMediaParams);
  const currentTime = useAppSelector(selectCurrentTime);
  const isBuffering = useAppSelector(selectIsBuffering);
  const isFfOrRw = useAppSelector(selectIsFfOrRw);
  const isPlaybackMarkerFocused = useAppSelector(selectIsPlaybackMarkerFocused);
  const isVideoContentVisible = useAppSelector(selectIsVideoContentVisible);
  const isLive = useAppSelector(selectIsLive);
  const seekOffset = useAppSelector(selectSeekOffset);
  const seekPointSeconds = useAppSelector(selectSeekPointSeconds);
  const seekSpeedMultiplier = useAppSelector(selectSeekSpeedMultiplier);
  const seekSpeedInterval = selectSeekSpeedInterval(seekSpeedMultiplier);
  const startTime = useAppSelector(selectStartTime);
  const totalTime = useAppSelector(selectTotalTime);
  const { LIVE_POINT_OFFSET, LIVE_SEEK_TIME_OFFSET } = useAppSelector(selectOffsets);
  const isDSSExperience = useAppSelector(selectIsDSSExperience);
  const isSeekPointBeforeStart = seekPointSeconds < startTime;
  const isSeekPointAfterEnd = seekPointSeconds > totalTime;
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const { handleFastForwardToLiveTTS, handlePlayPauseTTS, handleRewindToStartTTS } =
    useScrubberBarTTS();
  const playAtTime = useCallback(
    (seekTime: number, pause = false) => {
      clearTimeout(timer.current);

      const playTime = calculatePlayTime({
        LIVE_POINT_OFFSET,
        LIVE_SEEK_TIME_OFFSET,
        seekTime,
        totalTime,
      });

      seekTo(playTime);
      dispatch(seekInterrupted(playTime));
      const updatedIsAtLivePoint = totalTime - playTime <= msToSeconds(LIVE_POINT_OFFSET);
      dispatch(setIsAtLivePoint(updatedIsAtLivePoint));
      if (pause) dispatch(setPlaybackAction(PlaybackAction.PAUSE));

      if (shouldFetchPlayByPlay && isLive && gamePk) {
        dispatch(fetchPlayByPlayFlow(gamePk));
        setShouldFetchPlayByPlay(false);
      }
      handlePlayPauseTTS(playTime);
      AdobeTracker.trackAction(VIDEO_PLAYER_CONTROLS_SCRUB_JUMP, adobeMediaParams);
    },
    [
      LIVE_POINT_OFFSET,
      LIVE_SEEK_TIME_OFFSET,
      adobeMediaParams,
      dispatch,
      gamePk,
      handlePlayPauseTTS,
      isLive,
      seekTo,
      shouldFetchPlayByPlay,
      totalTime,
    ],
  );

  const updateSeekOffset = useCallback(() => {
    timer.current = setTimeout(() => {
      dispatch(setSeekOffset(seekOffset + seekSpeedInterval));
      dispatch(setSeekPointSeconds(currentTime + seekOffset));

      if (isSeekPointBeforeStart) {
        playAtTime(startTime);
        handleRewindToStartTTS();
      } else if (isSeekPointAfterEnd && isLive) {
        playAtTime(totalTime);
        handleFastForwardToLiveTTS();
      } else if (isSeekPointAfterEnd && !isLive) {
        // Exit the video if user seeked to the end of a video
        const to = (location.state as any)?.from || GAMES;
        const search = to === GAMES ? `?date=${yearMonthDay(date)}` : '';

        navigate({ pathname: to, search }, { state: { gamePk: gamePk ?? location.state?.gamePk } });
        dispatch(resetPlayer());
      }
    }, 100);
  }, [
    currentTime,
    date,
    dispatch,
    gamePk,
    handleFastForwardToLiveTTS,
    handleRewindToStartTTS,
    isLive,
    isSeekPointAfterEnd,
    isSeekPointBeforeStart,
    location.state,
    navigate,
    playAtTime,
    seekOffset,
    seekSpeedInterval,
    startTime,
    totalTime,
  ]);

  const handleSeek = useCallback(
    (ffOrRw: string, isButtonFforRw = false) => {
      const isVideoContentVisibleAndPlaybackMarkerFocused =
        isVideoContentVisible && isPlaybackMarkerFocused;
      const shouldBeginSeeking = isVideoContentVisibleAndPlaybackMarkerFocused || isButtonFforRw;
      let newSeekSpeed;

      const disableFF = !isFfOrRw && currentTime === totalTime && ffOrRw === FF;
      if (disableFF) {
        return;
      }

      // Prevents marker from jumping forward or backwards before starting to seek
      dispatch(setSeekPointSeconds(currentTime + seekOffset));

      if (ffOrRw === FF && shouldBeginSeeking) {
        newSeekSpeed = incrementSeekRate(seekSpeedMultiplier);
        dispatch(setSeekSpeedMultiplier(newSeekSpeed));
        if (newSeekSpeed === 2) {
          dispatch(setPlaybackAction(PAUSE_FF));
          if (gamePk && isDSSExperience) setShouldFetchPlayByPlay(true);

          AdobeTracker.trackAction(VIDEO_PLAYER_FAST_FORWARD, adobeMediaParams);
        }
        if (newSeekSpeed > 0) {
          speak(t('player.scrubber.fastForward', { seekSpeed: newSeekSpeed }));
        } else {
          speak(t('player.scrubber.rewind', { seekSpeed: Math.abs(newSeekSpeed) }));
        }
      } else if (ffOrRw === RW && shouldBeginSeeking) {
        newSeekSpeed = decrementSeekRate(seekSpeedMultiplier);
        dispatch(setSeekSpeedMultiplier(newSeekSpeed));
        if (newSeekSpeed === -2) {
          dispatch(setPlaybackAction(PAUSE_RW));
          AdobeTracker.trackAction(VIDEO_PLAYER_REWIND, adobeMediaParams);
        }
        if (newSeekSpeed > 0) {
          speak(t('player.scrubber.fastForward', { seekSpeed: newSeekSpeed }));
        } else {
          speak(t('player.scrubber.rewind', { seekSpeed: Math.abs(newSeekSpeed) }));
        }
        updateSeekOffset();
      }
    },
    [
      adobeMediaParams,
      currentTime,
      dispatch,
      gamePk,
      isDSSExperience,
      isFfOrRw,
      isPlaybackMarkerFocused,
      isVideoContentVisible,
      seekOffset,
      seekSpeedMultiplier,
      speak,
      t,
      totalTime,
      updateSeekOffset,
    ],
  );

  const handleCancelSeek = () => {
    clearTimeout(timer.current);
    dispatch(seekInterrupted(currentTime));
  };

  useEffect(() => {
    if (isFfOrRw) {
      updateSeekOffset();
    }

    return () => clearTimeout(timer.current);
  }, [isFfOrRw, updateSeekOffset]);

  useController({
    [Buttons.back]: {
      enabled: isFfOrRw,
      event: handleCancelSeek,
    },
    [Buttons.enter]: {
      enabled: isFfOrRw,
      event: () => playAtTime(seekPointSeconds),
    },
    [Buttons.fastForward]: {
      enabled: !isBuffering,
      event: () => handleSeek(FF, true),
    },
    [Buttons.left]: {
      enabled: !isBuffering,
      event: () => handleSeek(RW),
    },
    [Buttons.pause]: {
      enabled: isFfOrRw,
      event: () => playAtTime(seekPointSeconds, true),
    },
    [Buttons.play]: {
      enabled: isFfOrRw,
      event: () => playAtTime(seekPointSeconds),
    },
    [Buttons.playPause]: {
      enabled: isFfOrRw,
      event: () => playAtTime(seekPointSeconds),
    },
    [Buttons.rewind]: {
      enabled: !isBuffering,
      event: () => handleSeek(RW, true),
    },
    [Buttons.right]: {
      enabled: !isBuffering,
      event: () => handleSeek(FF),
    },
  });
}
