import styled from 'styled-components';
import { matchupColors } from 'styles/colors';
import { Z_INDICES } from 'styles/zIndexHierarchy';

const {
  GAME_TILE_HEADER_AWAY_TEAM,
  GAME_TILE_HEADER_CONTENT,
  GAME_TILE_HEADER_DIVIDER,
  GAME_TILE_HEADER_HOME_TEAM,
  GAME_TILE_HEADER_OVERLAY,
} = Z_INDICES;

const { dividerColor, overlayColor, textColor } = matchupColors;

interface MatchupContainerProps {
  $isRich: boolean;
}

interface MatchupContentProps {
  $focused: boolean;
}

interface MatchupTeamProps {
  $backgroundColor: string;
  $isRich?: boolean;
}

type MatchupDividerProps = MatchupContainerProps;

export const GameStatus = styled.span`
  font-size: ${({ theme }) => theme.fontSize.md};
  position: absolute;
  top: 5%;
`;

export const MatchupContainer = styled.div<MatchupContainerProps>`
  display: flex;
  height: ${({ $isRich }) => ($isRich ? '20.85rem' : '12.9rem')};
  position: relative;
  width: ${({ $isRich }) => ($isRich ? '41.1rem' : '100%')};
`;

export const MatchupContent = styled.div<MatchupContentProps>`
  align-items: center;
  color: ${textColor};
  display: flex;
  height: 100%;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  z-index: ${GAME_TILE_HEADER_CONTENT};

  // Gradient at top of the GameTile when focused
  ${({ $focused }) =>
    $focused &&
    `
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0.4) 100%
      );
      border-radius: 0.3rem;
      padding: 0 0.3rem 0 0.3rem;
      margin: 0;
    `}
`;

export const MatchupAwayTeam = styled.div<MatchupTeamProps>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.md};
  clip-path: ${({ $isRich }) =>
    $isRich ? 'polygon(0 0, 55% 0, 45% 100%, 0 100%)' : 'polygon(0 0, 53% 0, 47% 100%, 0 100%)'};
  height: 100%;
  width: 100%;
  z-index: ${GAME_TILE_HEADER_AWAY_TEAM};
`;

export const MatchupDivider = styled.div<MatchupDividerProps>`
  background-color: ${dividerColor};
  clip-path: ${({ $isRich }) =>
    $isRich
      ? 'polygon(54.8% 0, 55% 0, 46% 100%, 45.8% 100%)'
      : 'polygon(53.1% 0, 53% 0, 47% 100%, 47.1% 100%)'};
  height: 100%;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  z-index: ${GAME_TILE_HEADER_DIVIDER};
`;

export const MatchupHomeTeam = styled.div<MatchupTeamProps>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.md};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.md};
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: ${GAME_TILE_HEADER_HOME_TEAM};
`;

export const MatchupOverlay = styled.div`
  background-color: ${overlayColor};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.md};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.md};
  height: 100%;
  opacity: 0.7;
  position: absolute;
  width: 100%;
  z-index: ${GAME_TILE_HEADER_OVERLAY};
`;
