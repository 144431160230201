import { useReloadApp } from 'core-app/hooks';
import { getDeviceTTSEnabled } from 'utils/platform';
import { useEffect } from 'react';
import { setIsBackgrounded } from 'store/app';
import { useAppDispatch } from 'store/hooks';
import { setTTSEnabled } from 'core-app/store/deviceSettings';
import { ROUTES } from 'constants/screens';

export function useIsAppBackgrounded() {
  const dispatch = useAppDispatch();
  const reloadApp = useReloadApp();

  useEffect(() => {
    const backgroundListener = () => dispatch(setIsBackgrounded(true));
    const foregroundListener = async () => {
      dispatch(setIsBackgrounded(false));
      const ttsEnabled = await getDeviceTTSEnabled();
      dispatch(setTTSEnabled(ttsEnabled));
    };
    const resumeApp = () => reloadApp(ROUTES.HOME);

    window.msdk?.addEventListener('onAppResume', resumeApp);
    window.msdk?.addEventListener('onBackground', backgroundListener);
    window.msdk?.addEventListener('onForeground', foregroundListener);

    return () => {
      window.msdk?.removeEventListener('onAppResume', reloadApp);
      window.msdk?.removeEventListener('onBackground', backgroundListener);
      window.msdk?.removeEventListener('onForeground', foregroundListener);
    };
  }, [dispatch, reloadApp]);
}
