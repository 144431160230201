import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDeviceCaptionStyle } from 'utils/platform';
import { RootState } from 'store';

export interface IFA {
  IFA: string;
  IFA_TYPE: string;
  LMT: number;
}

export interface DeviceSettingsState {
  captionStyle: string;
  ifaParams?: IFA;
  ttsEnabled: boolean;
}

export const initialState: DeviceSettingsState = {
  captionStyle: getDeviceCaptionStyle(),
  ifaParams: undefined,
  ttsEnabled: false,
};

// Slice
export const deviceSettingsSlice = createSlice({
  initialState,
  name: 'deviceSettings',
  reducers: {
    setCaptionStyle(state: DeviceSettingsState, action: PayloadAction<string>) {
      state.captionStyle = action.payload;
    },
    setIFAParams(state: DeviceSettingsState, action: PayloadAction<IFA>) {
      state.ifaParams = action.payload;
    },
    setTTSEnabled(state: DeviceSettingsState, action: PayloadAction<boolean>) {
      state.ttsEnabled = action.payload;
    },
  },
});

// Selectors
export const selectCaptionStyle = (state: RootState) => state.deviceSettings.captionStyle;
export const selectIFAParams = (state: RootState) => state.deviceSettings.ifaParams;
export const selectTTSEnabled = (state: RootState) => state.deviceSettings.ttsEnabled ?? false;

// Actions
export const { setCaptionStyle, setIFAParams, setTTSEnabled } = deviceSettingsSlice.actions;

export default deviceSettingsSlice.reducer;
