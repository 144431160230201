import { useTextToSpeech } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import { selectTeamNameById } from 'store/branding';
import { useAppSelector } from 'store/hooks';
import { selectScoreAtTime } from 'store/playByPlay';
import { selectIsPlaying, selectStartTime, selectTotalTime } from 'store/player';
import { selectContentType, selectCurrentInning, selectGameTeamIds } from 'store/selectedVideo';
import { formatTimeStringTts, numberToOrdinalWord } from 'utils/textToSpeech/utils';
import { formatVideoTime } from 'utils/time';

export const useScrubberBarTTS = () => {
  const [params] = useSearchParams();
  const gamePk = params.get('gamePk');
  const isPlaying = useAppSelector(selectIsPlaying);
  const contentType = useAppSelector(selectContentType);
  const startTime = useAppSelector(selectStartTime);
  const totalTime = useAppSelector(selectTotalTime);
  const { currentInning, isTop } = useAppSelector(selectCurrentInning);
  const { awayTeamId, homeTeamId } = useAppSelector(selectGameTeamIds);
  const awayTeamName = useAppSelector((state) => selectTeamNameById(state, awayTeamId));
  const homeTeamName = useAppSelector((state) => selectTeamNameById(state, homeTeamId));
  const { awayTeamRuns, homeTeamRuns } = useAppSelector((state) =>
    selectScoreAtTime(state, gamePk),
  );

  const { speakImmediately, t } = useTextToSpeech();

  // TTS
  const currentPlayOrPause = isPlaying ? t('player.scrubber.paused') : t('player.scrubber.playing');
  const nextPlayOrPause = isPlaying ? t('player.scrubber.play') : t('player.scrubber.pause');

  const inningTTS = currentInning !== undefined ? numberToOrdinalWord(currentInning) : '';
  const halfInning = isTop ? t('player.scrubber.top') : t('player.scrubber.bottom');
  const score = isTop ? awayTeamRuns : homeTeamRuns;
  const team = isTop ? awayTeamName : homeTeamName;

  const handlePlayPauseTTS = (currentTime: number) => {
    const formattedCurrentTime = formatVideoTime(currentTime - startTime);
    const formattedTotalTime = formatVideoTime(totalTime - startTime);
    const formattedTimeToLive = formatVideoTime(totalTime - currentTime);

    if (contentType === 'VOD' || contentType === 'SVOD') {
      speakImmediately(
        t('player.scrubber.resumeVOD', {
          currentPlayOrPause,
          currentTime: formatTimeStringTts(formattedCurrentTime),
          nextPlayOrPause,
          totalTime: formatTimeStringTts(formattedTotalTime),
        }),
      );
    }
    if (contentType === 'SLIVE') {
      speakImmediately(
        t('player.scrubber.resumeSLIVE', {
          currentPlayOrPause,
          nextPlayOrPause,
          timeToLive: formatTimeStringTts(formattedTimeToLive),
        }),
      );
    }
    if (contentType === 'GAME') {
      speakImmediately(
        t('player.scrubber.resume', {
          currentPlayOrPause,
          halfInning,
          inningTTS,
          nextPlayOrPause,
          score,
          team,
        }),
      );
    }
  };

  const handleRewindToStartTTS = () => {
    if (contentType === 'VOD' || contentType === 'SVOD') {
      speakImmediately(t('player.scrubber.rewindStartVideo'));
    }
    if (contentType === 'GAME') {
      speakImmediately(t('player.scrubber.rewindStartGame'));
    }
  };

  const handleFastForwardToLiveTTS = () => {
    speakImmediately(t('player.scrubber.fastForwardToLive'));
  };

  return { handleFastForwardToLiveTTS, handlePlayPauseTTS, handleRewindToStartTTS };
};
