import api, { GET } from 'services/api';
import { ScoreboardFlipResponse } from './types';
import { ScoreboardFlipInfo } from 'store/scoreboardFlip/types';

export async function fetchScoreboardFlipData(): Promise<ScoreboardFlipInfo> {
  try {
    const response = await api<ScoreboardFlipResponse>(GET, {
      endpoint: 'json',
      service: 'scoreboardFlip',
    });
    return response.data.scoreboard;
  } catch (error) {
    console.error('Could not fetch scoreboard flip data', error);
    throw error;
  }
}
