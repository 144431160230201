import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { selectDatesConfig, selectFeatureFlags } from 'store/config';
import { useAppSelector } from 'store/hooks';
import { parseISO, yearMonthDay } from 'utils/date';

export const useDateFromURL = () => {
  const { hasCalendarLoadDate } = useAppSelector(selectFeatureFlags);
  const { calendarLoadDate } = useAppSelector(selectDatesConfig);
  const [searchParams, setSearchParams] = useSearchParams();
  const dateFromURL = searchParams.get('date');

  const date = useMemo(() => {
    if (dateFromURL) {
      return new Date(parseISO(`${dateFromURL}T00:00:00`));
    } else if (hasCalendarLoadDate) {
      return new Date(parseISO(`${calendarLoadDate}T00:00:00`));
    } else {
      return new Date();
    }
  }, [calendarLoadDate, dateFromURL, hasCalendarLoadDate]);

  const setDate = useCallback(
    (nextDate: Date) => {
      const params = new URLSearchParams({ date: yearMonthDay(nextDate) });
      setSearchParams(params);
    },
    [setSearchParams],
  );

  return { date, setDate };
};
