import { HTMLMotionProps } from 'framer-motion';
import { selectFeatureFlags } from 'store/config';

import { Ball, BallMask, Laces, LacesContainer, SpinnerContainer } from './Spinner.styles';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { getDeviceHasSpinner } from 'utils/platform';
import { useLocation } from 'react-router-dom';

type SpinnerSize = 'small' | 'medium' | 'large';
export type SpinnerBackground = 'solid' | 'transparent';
export type SpinnerVariant = 'inline' | 'block' | 'fullScreen';

interface SpinnerProps {
  background?: SpinnerBackground;
  className?: string;
  'data-testid'?: string;
  isAnimated?: boolean;
  size?: SpinnerSize;
  variant?: SpinnerVariant;
}

export const sizes: { [key in SpinnerSize]: number } = {
  large: 5.7,
  medium: 4.8,
  small: 3.3,
};

export function Spinner({
  background = 'transparent',
  className = '',
  size = 'medium',
  variant = 'block',
  ...props
}: SpinnerProps & HTMLMotionProps<'div'>) {
  const [isAnimated, setIsAnimated] = useState<boolean>(false);
  const featureFlags = useAppSelector(selectFeatureFlags);
  const { hasSpinner } = featureFlags.animations;
  const location = useLocation();
  const spinnerSize = variant === 'fullScreen' ? sizes.large : sizes[size];
  const isVideoRoute = location?.pathname?.includes('Video');

  useEffect(() => {
    if (!isVideoRoute) return setIsAnimated(true);
    const fn = async () => await getDeviceHasSpinner(hasSpinner);
    fn().then(setIsAnimated);
  }, [hasSpinner, isVideoRoute]);

  return (
    <SpinnerContainer
      $background={background}
      $variant={variant}
      aria-busy="true"
      aria-live="polite"
      className={className}
      data-testid={props['data-testid'] ?? 'spinner'}
      role="alert"
      {...props}>
      <BallMask $size={spinnerSize}>
        <Ball $size={spinnerSize}>
          <LacesContainer $isAnimated={isAnimated}>
            {/* Three laces assets are needed to create an infinite spinning slideshow effect */}
            <Laces $size={spinnerSize} />
            <Laces $size={spinnerSize} />
            <Laces $size={spinnerSize} />
          </LacesContainer>
        </Ball>
      </BallMask>
    </SpinnerContainer>
  );
}
