import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ScoreboardFlipInfo } from 'store/scoreboardFlip/types';
import { fetchScoreboardFlipData } from 'services/scoreboardFlip';
import { RequestStatus } from 'services/api';
import { RootState } from 'store';
import { FLIP_TIME_DEFAULT, FLIP_TIMEZONE_DEFAULT } from 'constants/date';

export interface ScoreboardFlipState {
  error: string;
  flipTime: string;
  flipTimeZone: string;
  status: RequestStatus;
}

export const initialState: ScoreboardFlipState = {
  error: '',
  flipTime: FLIP_TIME_DEFAULT,
  flipTimeZone: FLIP_TIMEZONE_DEFAULT,
  status: RequestStatus.IDLE,
};

export const fetchScoreboardFlipFlow = createAsyncThunk<
  ScoreboardFlipInfo,
  undefined,
  { rejectValue: string }
>('scoreboardFlip/fetchScoreboardFlip', async (_, { rejectWithValue }) => {
  try {
    const scoreboardFlipResponse = await fetchScoreboardFlipData();
    return scoreboardFlipResponse;
  } catch (error: any) {
    return rejectWithValue(error?.message);
  }
});

export const scoreboardFlipSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchScoreboardFlipFlow.pending, (state) => {
      state.status = RequestStatus.LOADING;
    });

    builder.addCase(fetchScoreboardFlipFlow.fulfilled, (state, action) => {
      state.status = RequestStatus.SUCCESS;
      state.flipTime = action.payload.flipTime;
      state.flipTimeZone = action.payload.flipTimeZone;
      state.error = '';
    });

    builder.addCase(fetchScoreboardFlipFlow.rejected, (state, action) => {
      state.status = RequestStatus.ERROR;
      state.error = action.payload ?? '';
    });
  },
  initialState,
  name: 'scoreboardFlip',
  reducers: {},
});

// Selectors
export const selectScoreboardFlip = (state: RootState) => state.scoreboardFlip;

export default scoreboardFlipSlice.reducer;
