import { useNavigate } from 'react-router-dom';

export const useReloadApp = () => {
  const navigate = useNavigate();

  return (loadRoute?: string) => {
    if (loadRoute) {
      navigate(loadRoute);
    }
    navigate(0);
  };
};
