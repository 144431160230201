import { useTTSEnabled } from 'hooks';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/textToSpeech/i18n';
import { speak, speakImmediately } from 'utils/textToSpeech/textToSpeech';

const noop = () => {};

export const useTextToSpeech = () => {
  const ttsEnabled = useTTSEnabled();
  const { t } = useTranslation(__PLATFORM__);

  const speakLng = (phrase: string) => (phrase ? speak(phrase, i18n.language) : noop());
  const speakLngImmediately = (phrase: string) =>
    phrase ? speakImmediately(phrase, i18n.language) : noop();

  return {
    speak: ttsEnabled ? speakLng : noop,
    speakImmediately: ttsEnabled ? speakLngImmediately : noop,
    t,
  };
};
