import Conviva from '@convivainc/conviva-js-coresdk';
import { ClientType } from '@mlbtv-clients/services';
import { MSDK_WIRED, WIRED, WIRELESS } from 'analytics/conviva/constants';
import { NAVIGATION_KEY_MAP } from 'utils/controllerKeymap';
import fallback from 'store/config/fallback.json';
import { EASTERN_TZ } from 'constants/date';

interface DeviceMetadata {
  deviceBrand: string;
  deviceManufacturer: string;
  deviceModel: string;
  operatingSystemName: string;
}

const {
  Constants: { DeviceCategory, DeviceMetadata, DeviceType },
} = Conviva;

const {
  BRAND,
  CATEGORY,
  MANUFACTURER,
  MODEL,
  OS_NAME,
  SCREEN_RESOLUTION_HEIGHT,
  SCREEN_RESOLUTION_WIDTH,
  TYPE,
} = DeviceMetadata;

// Disable window scrolling with arrow keys
const arrowKeys = new Set([
  ...NAVIGATION_KEY_MAP.down,
  ...NAVIGATION_KEY_MAP.left,
  ...NAVIGATION_KEY_MAP.right,
  ...NAVIGATION_KEY_MAP.up,
]);
window.addEventListener('keydown', (e) => {
  if (arrowKeys.has(e.keyCode)) {
    e.preventDefault();
  }
});

export const getClientType = async () => ClientType.PLAYSTATION;

// TODO: One day PS5 might let their users opt out of ads
export const getDeviceAdParams = (adParams: Record<string, string>, ifaParams: unknown) => adParams;

export const getDeviceCaptionStyle = () => ``;

export const getDeviceCountryCode = async () => {
  let userCountryCode = '';

  if (window.msdk) {
    try {
      const countryCodeObj = await window.msdk.psn.getAccountInfo(['countryCode']);

      userCountryCode = countryCodeObj.countryCode;
    } catch (e) {
      userCountryCode = '';
    }
  }

  return userCountryCode;
};

export const getDeviceConnectionType = async () => {
  let connectionType;

  if (window.msdk) {
    connectionType = await window.msdk.device.getNetworkConnectionType();
  }

  return connectionType === MSDK_WIRED ? WIRED : WIRELESS;
};

export const getDeviceExitApp = () => {
  // no exit app
};

export const getDeviceExitConfirmation = () => {
  // no exit dialog
};

export const getDeviceId = async (deviceId: string) => {
  let knownDeviceId = deviceId;

  if (window.msdk && !deviceId) {
    try {
      knownDeviceId = await window.msdk.device.getId();
    } catch (e) {
      console.error(`Failed to retrieve deviceId from ${__PLATFORM__}`, e);
    }
  }

  return knownDeviceId;
};

// TODO: does playstation have this information available
export const getDeviceKeyboardIsOpen = async () => false;

export const getDeviceMetadata = async (deviceMetadata: DeviceMetadata) => {
  const { deviceBrand, deviceManufacturer, deviceModel, operatingSystemName } = deviceMetadata;

  return {
    [BRAND]: deviceBrand,
    [CATEGORY]: DeviceCategory.PLAYSTATION,
    [MANUFACTURER]: deviceManufacturer,
    [MODEL]: deviceModel,
    [OS_NAME]: operatingSystemName,
    [SCREEN_RESOLUTION_HEIGHT]: window.screen.height,
    [SCREEN_RESOLUTION_WIDTH]: window.screen.width,
    [TYPE]: DeviceType.CONSOLE,
  };
};

export const getDeviceModelYear = async () => new Date().getFullYear().toString();

export const getDeviceHasSpinner = async (
  hasSpinner: typeof fallback.featureFlags.animations.hasSpinner,
) => !hasSpinner.hasAllModelsDisabled;

const timeZoneMap = new Map<number, string>([
  [-480, 'America/Los_Angeles'],
  [-420, 'America/Denver'],
  [-360, 'America/Chicago'],
  [-300, 'America/New_York'],
  [0, 'UTC'],
  [60, 'Europe/London'],
  [120, 'Europe/Athens'],
  [330, 'Asia/Kolkata'],
  [480, 'Asia/Shanghai'],
  [540, 'Asia/Tokyo'],
  [600, 'Australia/Sydney'],
]);

export const getDeviceTimeZone = async () => {
  if (window.msdk) {
    const deviceTimeZoneOffset = await window.msdk.system.getServiceParameter(4);
    return timeZoneMap.get(deviceTimeZoneOffset);
  }
  return EASTERN_TZ;
};

export const getDeviceTTSEnabled = async () => {
  let ttsSettings;
  if (window.msdk) {
    ttsSettings = await window.msdk.system.getTextToSpeechSettings();
  }

  return !!ttsSettings?.enabled;
};
