import { Middleware } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const logger: Middleware<null, RootState> = (store) => (next) => (action: any) => {
  if (!store.getState().config.data.featureFlags.hasReduxLogging) return next(action);
  console.groupCollapsed(`dispatching ${action.type}`);
  console.log('previous state', store.getState());
  console.log('action', action);
  const result = next(action);
  console.log('next state', store.getState());
  console.groupEnd();
  return result;
};
