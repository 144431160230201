import { DEFAULT_SPATIAL_NAV_SETTINGS } from 'constants/focus';
import { ROUTES } from 'constants/screens';
import { ARROW_KEYS } from 'constants/spatialNavigation';
import { screenIds } from 'constants/testIds';
import { useController, useTextToSpeech, useTTSEnabled } from 'hooks';
import { without } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { selectOpenModal } from 'store/app';
import { useAppSelector } from 'store/hooks';
import { Buttons } from 'utils/buttons';
import { getTextToSpeechStrategyStorage } from 'utils/storage';
import { TTSStrategy } from 'utils/textToSpeech/types';
import { utteranceFromIdReferenceList } from 'utils/textToSpeech/utteranceFromIdReferenceList';
import { utteranceListFromElement } from 'utils/textToSpeech/utteranceListFromElement';
import { getArrowDirectionText, waitForElement } from './utils';

const { ScreenReader } = TTSStrategy;
const { throttle } = DEFAULT_SPATIAL_NAV_SETTINGS;

export const useTTSNavigation = () => {
  const [prevActiveElement, setPrevActiveElement] = useState<Element | null>(null);
  const [prevUtteranceList, setPrevUtteranceList] = useState<string[]>([]);
  const openModal = useAppSelector(selectOpenModal);

  const textToSpeechStrategy = getTextToSpeechStrategyStorage();

  const ttsEnabled = useTTSEnabled();
  const { speak, t } = useTextToSpeech();
  const { pathname } = useLocation();

  const handleKeyup = useCallback(
    async (e: KeyboardEvent) => {
      setTimeout(async () => {
        if (!ttsEnabled) return;

        let utteranceToSpeak = '';
        const isArrowPress = ARROW_KEYS.has(e.keyCode);
        const focusChanged = prevActiveElement !== document.activeElement;
        const liveRegion = document.getElementById('aria-live-region');
        // ensure screen is loaded before speaking
        const screenId = pathname === ROUTES.HOME ? screenIds.HOME : `${pathname.slice(1)}Screen`;
        const screen = await waitForElement(`[data-testid="${screenId}"]`);

        const buildUtterance = () => {
          const newUtteranceList = utteranceListFromElement(document.activeElement);
          utteranceToSpeak = without(newUtteranceList, ...prevUtteranceList).join(' . ');
          setPrevUtteranceList(newUtteranceList);
        };

        // focus did not change
        if (isArrowPress && !focusChanged) {
          // if the active element has a TTS override, prevent adding 'no selectable content' tts
          if (document.activeElement?.hasAttribute('data-tts-override')) {
            buildUtterance();
          } else {
            const arrowDirectionText = getArrowDirectionText(e.keyCode);
            utteranceToSpeak = [
              t(`noSelectableContent.${arrowDirectionText}`),
              utteranceFromIdReferenceList(`noSelectableContent-${arrowDirectionText}`),
            ].join(' . ');
          }
        }

        // focus changed
        if ((focusChanged || openModal) && screen) {
          buildUtterance();
        }

        // determine which TTS strategy to use
        if (utteranceToSpeak) {
          if (liveRegion && textToSpeechStrategy === ScreenReader) {
            liveRegion.textContent = utteranceToSpeak;
          } else {
            speak(utteranceToSpeak);
          }
        }

        setPrevActiveElement(document.activeElement);
      }, throttle + 1);
    },
    [
      openModal,
      pathname,
      prevActiveElement,
      prevUtteranceList,
      speak,
      t,
      textToSpeechStrategy,
      ttsEnabled,
    ],
  );

  useEffect(() => {
    // trigger synthetic keyup event to handle speech when landing on a new screen
    setTimeout(() => handleKeyup(new KeyboardEvent('keyup')), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useController({
    [Buttons.back]: {
      event: (e) => {
        // delay to give time for modal to close
        setTimeout(() => handleKeyup(e), 1000);
      },
    },
    [Buttons.down]: {
      event: handleKeyup,
    },
    [Buttons.enter]: {
      event: (e) => {
        // delay to give time for modal to open
        setTimeout(() => handleKeyup(e), 1000);
      },
    },
    [Buttons.left]: {
      event: handleKeyup,
    },
    [Buttons.right]: {
      event: handleKeyup,
    },
    [Buttons.up]: {
      event: handleKeyup,
    },
  });
};
